import React, { useEffect, useState } from "react";
import { log } from "~/utils/db.server.js";
import { stateLoader, stateGet, stateSet } from "~/helpers/waypoints.js";

export default function Alert({ request, message, type, duration = 1000000 }) {
  const [closed, setClosed] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, duration);

    return () => clearTimeout(timer);
  }, []);

  const handleAlertCloseClick = async () => {
    try {
      const response = await fetch('/alert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ alertClosed: true })
      });


      if (response.ok) {

        //let state = await stateGet()
        //if (typeof state !== 'object') {
        //  state = {}
        //}
        let state = {}
        state.alertClosed = true
        await stateSet(JSON.stringify(state))

        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Optional: smooth scrolling animation
        });

        setClosed(true);
      } else {
        throw new Error('Failed to close alert');
      }
    } catch (error) {
      console.error('Error closing alert:', error);
    }
  };

  if (closed) {
    return null;
  }

  let alertColor = "";
  switch (type) {
    case "error":
      alertColor = "rgb(239, 68, 68)";
      break;
    case "warning":
      alertColor = "rgb(234, 179, 8)";
      break;
    case "success":
      alertColor = "#00ce22";
      break;
    default:
      alertColor = "#333";
  }

  const alertClass = `alert fade-in content extra-light pulse${fadeOut ? " fade-out" : ""}`;

  return (
    <div className={alertClass} style={{ backgroundColor: alertColor, position: "sticky", top: 0, width: "100%", padding: "10px", textAlign: "center" }}>
      <p className="message" dangerouslySetInnerHTML={{ __html: message }} />
      <button onClick={handleAlertCloseClick} style={{ marginLeft: "10px", cursor: "pointer", border: "none", backgroundColor: "transparent" }}>✖</button>
    </div>
  );
}
